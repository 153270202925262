/* Event Section Styles */
.my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-bottom: 100px;
}

.text-center {
    text-align: center;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.card {
    border: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

.card-text {
    font-size: 0.95rem;
    color: #555;
}

.card img {
    width: 100%;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
}

.row {
    display: flex;
    width: 100%;
    justify-content: center;
}

.event-image {
    height: 200px; /* Adjust the height as needed */
    object-fit: cover; /* Ensures the image covers the area while maintaining its aspect ratio */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* LinkedIn Embed Styles */
.linkedin-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 16px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.linkedin-card h4 {
    margin-bottom: 10px;
    color: #0073b1; /* LinkedIn color */
}

.linkedin-frame {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 47rem;
}

/* Optional: Styling for the title */
.linkedin-card p {
    color: #333;
    font-size: 14px;
    margin-bottom: 10px;
}

.linkedin-card a {
    text-decoration: none;
    color: #0073b1;
    font-weight: bold;
}