/* Conteneur pour chaque logo dans le slider */
.logo-item {
    display: flex;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    height: 100%; /* Prend toute la hauteur disponible dans le slider */
    text-align: center;
    padding: 10px; /* Ajoutez un peu d'espace autour de l'image, ajustez si nécessaire */
  }
  
  /* Image dans le slider */
  .logo-slider img {
    max-width: 100%; /* Utilise toute la largeur disponible */
    max-height: 100%; /* Utilise toute la hauteur disponible */
    width: auto; /* Maintient les proportions */
    height: auto; /* Maintient les proportions */
    object-fit: contain; /* Évite les déformations */
  }
  .logo-slider {
    max-width: 25%; /* Limite la largeur du slider à 80% de la largeur de la page */
    margin: 0 auto; /* Centre le slider horizontalement */
    padding: 20px 0; /* Ajoute un peu de padding pour l'espacement */
  }
  